/* cyrillic */
@font-face {
    font-family: 'Seymour One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/seymourone/v20/4iCp6Khla9xbjQpoWGGd0lyLN4Ffgg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Seymour One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/seymourone/v20/4iCp6Khla9xbjQpoWGGd0lyBN4Ffgg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Seymour One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/seymourone/v20/4iCp6Khla9xbjQpoWGGd0lyPN4E.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@font-face {
    font-family: 'Soyuzmult';
    src: url('./soyuzmult/soyuzmult-webfont.woff2') format('woff2'),
         url('./soyuzmult/soyuzmult-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-Black.eot');
    src: local('Creata Black'), local('Creata-Black'),
    url('./creata/Creata-Black.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-Black.woff2') format('woff2'),
    url('./creata/Creata-Black.woff') format('woff'),
    url('./creata/Creata-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-Medium.eot');
    src: local('Creata Medium'), local('Creata-Medium'),
    url('./creata/Creata-Medium.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-Medium.woff2') format('woff2'),
    url('./creata/Creata-Medium.woff') format('woff'),
    url('./creata/Creata-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-ExtraLight.eot');
    src: local('Creata ExtraLight'), local('Creata-ExtraLight'),
    url('./creata/Creata-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-ExtraLight.woff2') format('woff2'),
    url('./creata/Creata-ExtraLight.woff') format('woff'),
    url('./creata/Creata-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-Bold.eot');
    src: local('Creata Bold'), local('Creata-Bold'),
    url('./creata/Creata-Bold.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-Bold.woff2') format('woff2'),
    url('./creata/Creata-Bold.woff') format('woff'),
    url('./creata/Creata-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-ExtraLightItalic.eot');
    src: local('Creata ExtraLight Italic'), local('Creata-ExtraLightItalic'),
    url('./creata/Creata-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-ExtraLightItalic.woff2') format('woff2'),
    url('./creata/Creata-ExtraLightItalic.woff') format('woff'),
    url('./creata/Creata-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-Italic.eot');
    src: local('Creata Italic'), local('Creata-Italic'),
    url('./creata/Creata-Italic.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-Italic.woff2') format('woff2'),
    url('./creata/Creata-Italic.woff') format('woff'),
    url('./creata/Creata-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-BlackItalic.eot');
    src: local('Creata Black Italic'), local('Creata-BlackItalic'),
    url('./creata/Creata-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-BlackItalic.woff2') format('woff2'),
    url('./creata/Creata-BlackItalic.woff') format('woff'),
    url('./creata/Creata-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-Light.eot');
    src: local('Creata Light'), local('Creata-Light'),
    url('./creata/Creata-Light.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-Light.woff2') format('woff2'),
    url('./creata/Creata-Light.woff') format('woff'),
    url('./creata/Creata-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-LightItalic.eot');
    src: local('Creata Light Italic'), local('Creata-LightItalic'),
    url('./creata/Creata-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-LightItalic.woff2') format('woff2'),
    url('./creata/Creata-LightItalic.woff') format('woff'),
    url('./creata/Creata-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-MediumItalic.eot');
    src: local('Creata Medium Italic'), local('Creata-MediumItalic'),
    url('./creata/Creata-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-MediumItalic.woff2') format('woff2'),
    url('./creata/Creata-MediumItalic.woff') format('woff'),
    url('./creata/Creata-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-Regular.eot');
    src: local('Creata'), local('Creata-Regular'),
    url('./creata/Creata-Regular.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-Regular.woff2') format('woff2'),
    url('./creata/Creata-Regular.woff') format('woff'),
    url('./creata/Creata-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-Thin.eot');
    src: local('Creata Thin'), local('Creata-Thin'),
    url('./creata/Creata-Thin.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-Thin.woff2') format('woff2'),
    url('./creata/Creata-Thin.woff') format('woff'),
    url('./creata/Creata-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-ThinItalic.eot');
    src: local('Creata Thin Italic'), local('Creata-ThinItalic'),
    url('./creata/Creata-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-ThinItalic.woff2') format('woff2'),
    url('./creata/Creata-ThinItalic.woff') format('woff'),
    url('./creata/Creata-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Creata';
    src: url('./creata/Creata-BoldItalic.eot');
    src: local('Creata Bold Italic'), local('Creata-BoldItalic'),
    url('./creata/Creata-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./creata/Creata-BoldItalic.woff2') format('woff2'),
    url('./creata/Creata-BoldItalic.woff') format('woff'),
    url('./creata/Creata-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}