.block {
  background-image: url("./pattern.svg");
  background-position-x: center;
  background-repeat: repeat-y;
  padding: 1px 0;
  background-size: 2000px auto;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .back {
    background-size: 150vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .back {
    background-size: 200vw;
  }
}