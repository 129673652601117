.block {
  max-width: var(--site-width);
  margin: 122px auto;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
  margin-bottom: 40px;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.item {
  display: block;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 30px 16px 20px;
  margin-bottom: 16px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: #716CDF;

  min-height: 52px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;
}

.date {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  color: #000;
  margin: 14px 0;
}

.image {
  border-radius: 30px;
  width: 100%;
  height: auto;
  margin-top: 24px;

  &:not(:nth-child(3n)) {
    margin-right: 15px;
  }
}

.button {
  font-family: 'Soyuzmult';
  background: #31D693;
  border-radius: 15px;
  padding: 12px 100px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  color: #FFFFFF;
  align-self: flex-end;
  cursor: pointer;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .items {
    grid-template-columns: 1fr 1fr;
  }

  .image {
    &:not(:nth-child(3n)) {
      margin-right: 0;
    }

    &:not(:nth-child(2n)) {
      margin-right: 15px;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .items {
    grid-template-columns: 1fr;
  }
}