.block {
  width: 1300px;
  height: 1170px;
  position: relative;
  margin: 80px auto;

  background: #FFFFFF;
  border-radius: 30px;

  padding: 30px 50px;
  box-sizing: border-box;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
}

.day {
  position: absolute;
  top: 215px;

  background: #F7F7F7;
  border-radius: 30px;

  width: 193px;
  height: 142px;

  color: #000000;
  font-weight: 500;
  line-height: 1;

  padding: 16px 24px;
  box-sizing: border-box;
  z-index: 2;
}

.dayNumber {
  font-size: 50px;
}

.dayMonth {
  font-size: 25px;
}

.dayInWeek {
  padding-top: 8px;
  font-size: 18px;
  color: #31D693;
}

.dayBig {
  width: 243px;
}

.day1 {
  left: 52px;
}

.day2 {
  left: 266px;
}

.day3 {
  left: 530px;
}

.day4 {
  left: 795px;
}

.day5 {
  left: 1059px;
}

.box {
  background: #F7F7F7;
  border-radius: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1;

  padding: 24px 30px;
  box-sizing: border-box;

  position: absolute;
  width: 193px;
  height: 137px;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
}

.in {
  top: 378px;
  left: 52px;
}

.out {
  top: 378px;
  left: 1059px;
}

.boxTitle {
  color: #FFFFFF;
  background: #716CDF;
  width: 772px;
  height: 52px;
}

.learning {
  top: 378px;
  left: 266px;
}

.festival {
  top: 734px;
  left: 266px;
  background: #F8822B;
}

.boxLearning {
  background: #F3ECFF;
  border: 1px solid #716CDF;

  > ul {
    padding: 0;
    list-style: none;

    > li {
      padding-left: 16px;
      border-left: 4px solid #633CAC;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

.opening {
  top: 451px;
  left: 266px;

  width: 243px;
  height: 110px;
}

.dialog {
  top: 451px;
  left: 530px;

  width: 243px;
  height: 110px;
}

.learningSchedule1 {
  top: 451px;
  left: 796px;

  width: 242px;
  height: 255px;
  text-align: left;

  padding-right: 5px;
  padding-top: 34px;
}

.learningSchedule2 {
  top: 584px;
  left: 267px;

  width: 507px;
  height: 122px;
  text-align: left;

  padding-top: 26px;

  > ul {
    columns: 2;
    margin-bottom: 0;

    > li {
      &:nth-child(3), &:nth-child(4) {
        position: relative;
        left: 20px;
      }

      &:nth-child(even) {
        margin-bottom: 0;
      }

      &:nth-child(odd) {
        margin-bottom: 18px;
      }
    }
  }
}

.boxFestival {
  background: #FFEBD7;
  border: 1px solid #F8822B;

  > ul {
    padding: 0;
    list-style: none;

    > li {
      padding-left: 16px;
      border-left: 4px solid #F8822B;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

.festivalSchedule1 {
  top: 807px;
  left: 267px;

  width: 771px;
  height: 146px;

  padding-left: 20px;

  > ul {
    columns: 3;
    margin-bottom: 0;

    > li {
      padding-left: 12px;
      text-align: left;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:nth-child(3),  &:nth-child(4) {
        position: relative;
        left: 20px;
      }
    }
  }
}

.festivalSchedule2 {
  top: 974px;
  left: 267px;

  width: 507px;
  height: 110px;

  padding-left: 20px;

  > ul {
    columns: 2;
    margin-bottom: 0;

    > li {
      padding-left: 12px;
      text-align: left;

      margin-bottom: 0 !important;

      &:nth-child(2) {
        position: relative;
        left: 32px;
      }
    }
  }
}

.show {
  top: 974px;
  left: 794px;

  width: 244px;
  height: 110px;
}

.line {
  width: 1px;
  background: #D7D7D7;
  height: 100px;
  position: absolute;
  z-index: 1;
  top: 320px;
}

.line1 {
  left: 150px;
}

.line2 {
  left: 388px;
  height: 700px;
}

.line3 {
  left: 652px;
  height: 700px;
}

.line4 {
  top: 320px;
  left: 917px;
  height: 700px;
}

.line5 {
  left: 1156px;
}

.mobileDay {
  display: none;
}

@media screen and (max-width: 1299px) {
  .block {
    background: none;
    width: 100%;
    height: unset;
  }

  .day, .box, .line {
    display: none;
  }

  .header {
    width: 100%;
  }

  .mobileDay {
    display: block;

    font-size: 4.375vw;
    line-height: 1;

    background: #FFFFFF;
    border-radius: 9.375vw;

    margin-top: 6vw;
    margin-bottom: 6vw;
    text-align: center;
    padding: 4.375vw 5vw;
    z-index: 2;

    &::before {
      content: '';
      height: 6vw;
      width: 1px;
      border-left: 1px solid #D7D7D7;

      position: relative;
      top: -9.5vw;
      z-index: 1;
    }
  }

  .mobileDayFirst {
    &::before {
      display: none;
    }
  }

  .mobileDate {
    font-size: 7.8125vw;
    font-weight: 500;
  }

  .mobileWeekDay {
    margin: 2.5vw auto;
    color: #31D693;
    font-size: 5vw;
    font-weight: 500;
  }

  .mobileElement {
    line-height: 1.1;
    background: #F7F7F7;
    border-radius: 9.375vw;
    padding: 6.25vw;
  }

  .mobileLearningHeader {
    color: #FFFFFF;
    background: #716CDF;
    font-weight: 500;
    padding: 3.125vw;
    margin: 2.5vw auto;
  }

  .mobileLearningElements {
    border: 1px solid #633CAC;
    background: #F3ECFF;

    > ul {
      padding: 0;
      list-style: none;

      > li {
        font-weight: 400;

        &:not(:last-child){
          margin-bottom: 4.375vw;
        }
      }
    }
  }

  .mobileFestivalHeader {
    background: #F8822B;
  }

  .mobileFestivalElements {
    background: #FFEBD7;
    border: 1px solid #F8822B;
  }

  .download {
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .block {
    margin-top: 0;
    padding: 20px;
  }
}