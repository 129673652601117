.block {
  max-width: var(--site-width);
  margin: 95px auto 157px;
  overflow: visible;
  position: relative;

  font-size: 16px;
  line-height: 24px;

  color: #000000;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.title {
  font-family: 'Soyuzmult';
  font-size: 60px;
  line-height: 60px;

  margin-bottom: 60px;

  color: #716CDF;

  align-self: flex-start;
  z-index: 2;

  width: 440px;
}

.back {
  position: absolute;
  left: 0;
  top: 113px;

  width: 835px;
  height: 663px;
}

.hint {
  position: absolute;
  right: -107px;
  bottom: -54px;

  width: 107px;
  height: 107px;
}

.hint2 {
  display: none;
}

.wrapper {
  position: relative;

  width: 576px;
  height: 275px;
}

.paper {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 34px 90px rgba(113, 108, 223, 0.2);
  border-radius: 16px 16px 0 16px;

  width: 470px;

  padding: 26px 36px;
  box-sizing: border-box;
}

.info {
  width: 365px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 2;
}

.narrow {
  line-height: 0.4;
}

.spacer {
  display: block;
  height: 16px;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .back {
    position: absolute;
    top: 390px;

    width: 533px;
    height: 423px;

    left:20%;
    transform:translateX(-50%);
  }

  .info {
    width: 265px;
  }

  .block {
    margin: 0 auto 27px;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .wrapper {
    width: 100%;
    height: unset;
  }

  .paper {
    border-radius: 16px 0 16px 16px;
    width: calc(100% - 100px);
    position: relative;
  }

  .title {
    margin-bottom: 72px;
  }

  .hint {
    display: none;
  }

  .hint2 {
    display: block;
    position: absolute;
    right: -103px;
    top: -77px;

    width: 107px;
    height: 107px;
  }

  .info {
    margin-top: 40px;
    width: 50%;
  }

  .back {
    position: absolute;
    bottom: 50px;
    top: unset;
    left: unset;

    width: 351px;
    height: 278px;

    right: 50%;
  }

  .block {
    margin-bottom: 40px;
  }
}