.block {
    background-color: white;
    width: 100%;
}

.inner {
    max-width: var(--site-width);

    padding-top: 40px;

    margin: 0 auto;
}

.logo {
    height: 86px;
}

.flex {
    display: flex;
}

.logoRu {
    width: 240px;
}

.logoEn {
    width: 230px;
}

.language {
    color: #F4F4F4;
    width: 54px;
    border-radius: 15px;
    text-decoration: none;
    display: block;
    z-index: 1;
    line-height: 38px;
    cursor: pointer;

    &:hover {
        color: #F4F4F4;
    }
}

.ru {
    position: absolute;
    right: 0;
}

.en {
    position: absolute;
    left: 0;
}

.active {
    background: #716CDF;
    z-index: 2;
}

.languages {
    position: relative;
    width: 96px;
    height: 38px;

    background: #D9D9D9;
    border-radius: 15px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    text-align: center;

    color: #F4F4F4;
}

.row1 {
    padding-bottom: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu {
    max-width: var(--site-width);
    padding-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-0);
    border-bottom: 2px solid white;

    &:hover {
        color: var(--color-0);
        border-bottom: 2px solid var(--color-1);
    }
}

.mobileMenu {
    display: none;
}

.openedMenu {
    display: none;
}

@media screen and (min-width: 320px) and (max-width: 719px) {
    .menu {
        display: none;
    }

    .logo {
        height: 50px;
    }

    .logoRu {
        width: 139px;
    }

    .logoEn {
        width: 134px;
    }

    .block {
        background: rgba(252, 252, 252, 0.6);
        backdrop-filter: blur(60px);
    }

    .mobileMenu {
        display: block;
        margin-left: 12px;
    }

    .openedMenu {
        display: flex;
        width: 100%;
        height: 100%;

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(60px);

        z-index: 10;
        padding: 40px 16px;

        box-sizing: border-box;

        flex-direction: column;
    }

    .openedMenuHeader {
        display: flex;
        justify-content: space-between;
    }

    .openedMenuItems {
        margin-top: 70px;
        font-weight: 400;
        font-size: 30px;
        line-height: 2.2;
        text-align: center;
        color: #000000;
    }

    .openedMenuItem {
        text-decoration: none;
        display: block;
    }
}