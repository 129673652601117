.relative {
  position: relative;
  overflow: hidden;
}

.block {
  max-width: var(--site-width);
  margin: 140px auto 220px;
  overflow: visible;

  display: flex;
  flex-direction: column;
  position: relative;
}

.title {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;

  color: #716CDF;

  align-self: flex-start;
  z-index: 2;
}

.text {
  width: 530px;
  align-self: flex-end;
  margin-right: 80px;
  margin-top: 80px;

  font-size: 24px;
  line-height: 35px;

  color: #000000;

  z-index: 2;
}

.info {
  margin-top: 90px;
  width: 610px;
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin-bottom: 50px;
}

.item {
  display: flex;
}

.icon {
  width: 95px;
  height: 95px;
  margin-right: 26px;
}

.itemLine1 {
  margin-top: 8px;
  font-size: 16px;
  line-height: 12px;
  color: #000000;
}

.itemLine2 {
  font-family: 'Soyuzmult';
  font-size: 60px;
  line-height: 50px;
  color: #716CDF;
}

.itemLine3 {
  font-family: 'Soyuzmult';
  font-size: 25px;
  line-height: 20px;
  color: #716CDF;
}

.back {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .block {
    margin: 40px auto;
  }

  .text {
    font-size: 18px;
    line-height: 26px;
  }

  .info {
    flex-direction: column;
    width: 470px;
  }

  .text {
    width: 384px;
  }

  .item {
    margin-bottom: 34px;
  }

  .back {
    height: 70vw;
    width: 116vw;
    left: -20vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .block {
    margin: 10px auto;
  }

  .text {
    width: unset;
    margin-right: 0;
    margin-top: 20px;
    font-size: 18px;
    line-height: 26px;
  }

  .info {
    width: unset;
    align-self: unset;
    margin-top: 40px;
  }

  .item {
    flex-direction: column;
  }

  .icon {
    width: 55px;
    height: 55px;
  }

  .itemLine2 {
    font-size: 50px;
    line-height: 1;
  }

  .back {
    height: 221px;
    width: 268px;
    right: -100px;
  }
}