.block {
  max-width: var(--site-width);
  margin: 122px auto;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
  margin-bottom: 40px;
}

.items {

}

.item {
  display: flex;
  background: #F3ECFF;
  border: 1px solid #716CDF;
  border-radius: 30px;
  overflow: hidden;

  margin-bottom: 40px;

  height: 455px;
}

.image {
  min-width: 788px;
  background-position: center;
  background-size: cover;
  height: 100%;
}

.info {
  padding: 40px 20px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: #716CDF;
}

.date {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  color: #000;
  margin: 14px 0;
}

.button {
  font-family: 'Soyuzmult';
  background: #31D693;
  border-radius: 15px;
  padding: 12px 100px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  color: #FFFFFF;
  align-self: flex-end;
  cursor: pointer;
}

@media screen and (max-width: 1299px) {
  .item {
    flex-direction: column;
    height: unset;
  }

  .imageWrapper {
    position: relative;
    padding-bottom: 56.25%;
  }

  .image {
    min-width: unset;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
