.toTop {
  position: fixed;
  width: 43px;
  height: 43px;

  right: 43px;
  bottom: 43px;

  z-index: 10;

  border-radius: 50%;
  border: 1px solid black;
}