.block {

}

.text {
  max-width: 600px;
  margin-right: 40px;
}

.info {
  display: flex;
}

.places {

}