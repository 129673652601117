.block {
  max-width: var(--site-width);
  margin: 122px auto;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
  margin-bottom: 40px;
  z-index: 2;
}

.days {
  z-index: 2;
}

.day {
  background: #FFFFFF;
  border-radius: 30px;
  padding: 40px;
  margin-bottom: 20px;
}

.finished {
  background: rgba(217, 217, 217, 0.5);
}

.date {
  font-family: 'Soyuzmult';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 1;

  color: #716CDF;

  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #716CDF;
}

.events {
  display: flex;
}

.event {
  flex: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.time {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  color: #000000;

  margin-bottom: 6px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #633CAC;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.link {
  font-family: 'Soyuzmult';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #716CDF;
  border: 1px solid #716CDF;
  border-radius: 15px;
  height: 38px;

  text-decoration: none;

  margin-top: 40px;
}

.back {
  position: absolute;
  bottom: 20px;
  left: -980px;

  width: 2004px;
  height: 1570px;

  z-index: 1;
}

@media screen and (max-width: 1299px) {
  .events {
    flex-direction: column;
  }

  .event {
    text-align: center;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}