.outer {
  position: relative;
  overflow: hidden;
}

.block {
  max-width: var(--site-width);
  margin: 86px auto 122px;
  position: relative;

  min-height: 550px;
}

.teaserLink {
  &:first-child {
    margin-right: 40px;
  }
}

.teaserLinkIcon {
  margin-right: 32px;
}

.teaserLinkText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  text-decoration-line: underline;

  color: #716CDF;
}

.teaserHeader {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;

  z-index: 2;

  margin-top: 20px;
}

.teaserCard {
  margin-top: 40px;
  background: #F3ECFF;
  border: 1px solid #716CDF;
  border-radius: 30px;
  padding: 40px 30px 24px;

  display: flex;
  flex-direction: column;
}

.teaserCardTitle {
  color: #716DDF;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
}

.teaserCardDate {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 18px;
}

.teaserCardText {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 18px;
}

.teaserCardButton {
  font-family: 'Soyuzmult';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;

  margin-top: 18px;
  color: #fff;
  background: #31D693;
  border-radius: 15px;
  padding: 12px 27px 8px;

  align-self: flex-end;
}

.slogan1 {

}

.slogan2 {
  margin-top: 19px;
  margin-left: 150px;
}

.back {
  position: absolute;
  right: -177px;
  top: -53px;

  width: 835px;
  height: 716px;
}

.hint {
  position: absolute;
  top: -89px;
  left: -111px;

  width: 111px;
  height: 145px;
}

.wrapper {
  position: relative;

  width: 633px;
  height: 307px;
}

.header {
  font-family: 'Soyuzmult';
  font-size: 30px;
  line-height: 1;

  color: #716CDF;
}

.paper {
  background: #FFFFFF;
  box-shadow: 0px 34px 90px rgba(113, 108, 223, 0.2);
  border-radius: 0 16px 16px 16px;

  width: 516px;

  padding: 26px 36px;
  box-sizing: border-box;

  position: absolute;
  bottom: 58px;
  right: 10px;
}

.paper2 {
  margin-left: 102px;

  background: #FFFFFF;
  width: 424px;
  border-radius: 16px;

  padding: 24px 42px;
  box-sizing: border-box;
}

.time {
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
}

.timeItem {
  background: #F4F4F4;
  border-radius: 15px;
  color: #716CDE;
  padding: 6px 13px;
}

.teaser {
  width: 590px;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .wrapper {
    height: 200px;
  }

  .paper {
    bottom: 20px;
  }

  .back {
    position: absolute;
    right: -127px;
    top: -83px;

    width: 514px;
    height: 456px;
  }

  .block {
    margin-bottom: 40px;
    min-height: 335px;
  }

  .teaserCard {
    width: 685px;
    margin-right: auto;
    margin-left: auto;
  }

  .teaser {
    width: unset;
  }

  .teaserHeader {
    margin-top: 60px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .back {
    position: absolute;
    right: -67px;
    top: -83px;

    width: 378px;
    height: 330px;
  }

  .slogan1 {
    width: 270px;
  }

  .slogan2 {
    margin-top: 19px;
    margin-left: 0;
    width: 300px;
  }

  .wrapper {
    width: 100%;
    height: unset;
    margin-top: 70px;
    margin-bottom: 20px;
  }

  .paper {
    width: calc(100% - 90px);
    position: relative;
    bottom: 0;
    right: -80px;
  }

  .hint {
    position: absolute;
    top: -73px;
    left: -91px;

    width: 91px;
    height: 119px;
  }

  .paper2 {
    width: unset;
    margin: 0;
    text-align: center;
    padding: 10px;
  }

  .block {
    margin-bottom: 70px;
    min-height: unset;
  }

  .teaserCard {
    margin-top: 60px;
    width: 100%;
  }

  .teaser {
    width: unset;
  }

  .teaserHeader {
    margin-top: 60px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 450px) {
  .teaserLinkContainer {
    display: flex;
    flex-direction: column;
  }

  .teaserLinkIcon {
    width: 30px;
    margin-right: 16px;
  }
}