.block {
  max-width: var(--site-width);
  margin: 122px auto;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
  margin-bottom: 40px;
}

.items {
  display: flex;
  margin-left: -20px;
  width: auto;
}

.column {
  padding-left: 20px;
  background-clip: padding-box;
}

.item {
  display: block;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 40px 35px;
  margin-bottom: 20px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  color: #716CDF;
}

.date {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  color: #000;
  margin: 14px 0;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
}

.image {
  border-radius: 30px;
  width: 100%;
  height: auto;
  margin-top: 40px;
}

.button {
  font-family: 'Soyuzmult';
  background: #31D693;
  border-radius: 15px;
  padding: 12px 100px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  color: #FFFFFF;
  align-self: flex-end;
  cursor: pointer;
}
