.block {
    width: 100%;
    background-color: #A686DD;
    color: #fff;
    font-size: 12px;
    padding-top: 20px;
    line-height: 1.6;
    z-index: 2;
}

.inner {
    margin: 0 auto;
    max-width: var(--site-width);

    display: flex;
    justify-content: space-between;

    font-size: 14px;
    line-height: 27px;
}

.logo1 {
    width: 118px;
    height: 46px;

    margin-bottom: 28px;
    display: block;
}

.logo2 {
    width: 221px;
    height: 61px;
}

.logo1S, .logo2S {
    display: none;
}

.tags {
    text-align: right;
}

.menu {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header {

}

.item {
    color: white;
}

.contacts {
    padding-top: 40px;
}

.copyright {
    font-size: 10px;
    line-height: 10px;
    padding-bottom: 40px;
    margin-top: 36px;
    align-items: flex-end;
    text-align: right;
}

.bold {
    font-weight: 700;
}

.links {
    padding: 0;
    list-style: none;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
    .logo1, .logo2 {
        display: none;
    }

    .logo1S, .logo2S {
        display: block;
    }

    .logo1S {
        margin-bottom: 24px;
    }

    .logos {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
    .inner {
        flex-direction: column;
        align-items: center;
        text-align: center;

        &.copyright {
            align-items: flex-start;
        }
    }

    .logos {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        & > * {
            flex: 1;

            &:first-child {
                margin-right: 50px;
            }
        }
    }

    .logo1 {
        margin-bottom: 0;
    }

    .logo1, .logo2 {
        width: 100%;
        height: unset;
    }

    .menu {
        margin-top: 56px;
        width: 100%;

        .bold span {
            display: inline-block;
            vertical-align: middle;
        }
        .bold:before,
        .bold:after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            height: 1px;
            background-color: #fff;
            position: relative;
            top: 2px;
        }
        .bold:before {
            margin-left: -100%;
            left: -20px;
        }
        .bold:after {
            margin-right: -100%;
            right: -20px;
        }
    }

    .links {
        text-align: center;
        columns: 3;
    }

    .tags, .bold {
        text-align: center;
    }

    .copyright {
        text-align: left;
    }
}