.block {
  position: fixed;

  top: 240px;

  left: calc((100% - var(--site-width)) / 2);
  z-index: 10;
}

.souz {
  margin-right: 20px;
  width: 181px;
  height: 74px;
}

.gorki {
  width: 243px;
  height: 74px;
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .block {
    top: 110px;
  }

  .souz {
    margin-right: 17px;
    width: 157px;
    height: 64px;
  }

  .gorki {
    width: 211px;
    height: 64px;
  }
}

@media screen and (max-width: 450px) {
  .block {
  }

  .souz, .gorki {
    height: 50px;
    width: auto;
  }

  .souz {
    margin-right: 10px;
  }
}