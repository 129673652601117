.block {
  width: 171px;
  height: 165px;
  background: url("./star.svg");

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 15px;
  padding-left: 15px;
  box-sizing: border-box;

  background-size: 100% 100%;
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.small {
  --title-font-size: 80px;
  --highlight-line-height: 0.7;

  width: 145px;
  height: 140px;
}

.mixed {
  --title-font-size: 40px;
  --highlight-line-height: 1;

  > :nth-child(2) {
    order: -1;
  }

  .subtitle {
    position: relative;
    top: -6px;
    font-size: 16px;
    line-height: 24px;
  }
}

.normal {
  --title-font-size: 45px;
  --highlight-line-height: 0.6;
}