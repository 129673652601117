.block {
  max-width: var(--site-width);
  margin: 95px auto 157px;
  overflow: visible;
  position: relative;

  height: 700px;
}

.back {
  position: absolute;
  right: 200px;
  top: 400px;
}

.title {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;

  margin-bottom: 60px;

  color: #716CDF;

  align-self: flex-start;
  z-index: 2;

  width: 440px;
}

.header {
  font-size: 16px;
  line-height: 19px;
  color: #000000;

  width: 167px;
}

.item {
  position: absolute;
  background: #FFFFFF;
  border-radius: 30px;

  box-sizing: border-box;
  padding: 20px;

  display: flex;
  align-items: flex-end;
}

.logo {
  width: 147px;
  height: 147px;

  position: absolute;
  right: 20px;
  top: 20px;
}

.item1 {
  top: 150px;
  left: 0;

  width: 363px;
  height: 189px;
}

.item2 {
  top: 354px;
  left: 0;

  width: 363px;
  height: 189px;
}

.item3 {
  top: 150px;
  left: 379px;

  width: 300px;
  height: 317px;
}

.item4 {
  top: 237px;
  right: 220px;

  width: 290px;
  height: 232px;
}

.item5 {
  top: -10px;
  right: 220px;

  width: 290px;
  height: 232px;

  .logo {
    width: 149px;
    height: 205px;

    top: -30px;
  }
}

.item6 {
  top: 150px;
  right: 0;

  width: 204px;
  height: 317px;
}

.item7 {
  top: 482px;
  left: 379px;

  width: 503px;
  height: 185px;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .block {
    height: 92vw;
  }

  .logo {
    width: 16.3vw;
    height: 16.3vw;
  }

  .item1 {
    top: 20.77vw;
    left: 0;

    width: 40.33vw;
    height: 21vw;
  }

  .item2 {
    top: 66.22vw;
    left: 50.77vw;

    width: 34.22vw;
    height: 21vw;
  }

  .item3 {
    top: 6.55vw;
    left: 42.44vw;

    width: 22.55vw;
    height: 35.22vw;
  }

  .item4 {
    top: 43.88vw;
    left: 24.77vw;

    width: 23.88vw;
    height: 48.11vw;
  }

  .item5 {
    top: 43.88vw;
    left: 0;

    width: 22.66vw;
    height: 33vw;

    .logo {
      width: 16.3vw;
      height: 22.66vw;

      top: -3.6vw;
    }
  }

  .item6 {
    top: 15.44vw;
    left: 67.11vw;

    width: 22.66vw;
    height: 26.33vw;
  }

  .item7 {
    top: 43.88vw;
    left: 50.77vw;

    width: 39vw;
    height: 20.55vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .block {
    height: 209vw;
  }

  .header {
    font-size: 3.2vw;
    line-height: 1.2;
    width: 30vw;
  }

  .logo {
    width: 22vw;
    height: 22vw;

    right: 4vw;
    top: 4vw;
  }

  .item1 {
    top: 15.2vw;
    left: 0;

    width: 32.6vw;
    height: 66vw;
  }

  .item2 {
    top: 15.2vw;
    left: 37.6vw;

    width: 57vw;
    height: 31vw;
  }

  .item3 {
    top: 85.2vw;
    left: 0;

    width: 93.6vw;
    height: 27.4vw;
  }

  .item4 {
    top: 50.2vw;
    left: 36.6vw;

    width: 58vw;
    height: 31vw;
  }

  .item5 {
    top: 116.6vw;
    left: 0;

    width: 44.8vw;
    height: 43.6vw;

    .logo {
      width: 22vw;
      height: 30vw;

      top: -4.4vw;
    }
  }

  .item6 {
    top: 116.6vw;
    left: 48.8vw;

    width: 44.8vw;
    height: 43.6vw;
  }

  .item7 {
    top: 164.2vw;
    left: 0;

    width: 93.6vw;
    height: 44.8vw;
  }
}