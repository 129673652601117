.block {
  max-width: var(--site-width);
  margin: 170px auto 40px;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
  margin-bottom: 40px;
  z-index: 2;
}

.subtitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #716DDF;
  z-index: 2;
}

.logo {
  margin-top: 40px;
  width: 165px;
  height: 122px;
}

.stars {
  height: 450px;
  position: relative;
  width: 480px;
  z-index: 2;
}

.star1 {
  position: absolute;
  top: 0;
  right: 0;
}

.star2 {
  position: absolute;
  top: 190px;
  right: 20px;
}

.wrapper {
  position: relative;
  padding-bottom: 51px;
  padding-right: 105px;
  width: 588px;
  z-index: 2;

  margin-bottom: 220px;
}

.paper {
  background: #FFFFFF;
  border-radius: 30px 30px 0 30px;
  padding: 24px 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.hint {
  background: url("./hint.svg");
  width: 130px;
  height: 106px;

  position: absolute;
  bottom: 0;
  right: 0;
}

.back {
  position: absolute;
  top: -80px;
  right: -250px;
  z-index: -1;
}

.back2 {
  position: absolute;
  bottom: 94px;
  right: -681px;
  width: 2004px;
  height: 1570px;

  z-index: -1;
}

.days {
  z-index: 2;
}

.dayTitle {
  font-family: 'Soyuzmult';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 1;
  color: #716CDF;
  margin-bottom: 60px;
}

.day {

}

.event {
  background: #FFFFFF;
  border-radius: 30px;

  padding: 30px 40px 30px;

  margin-bottom: 40px;

  --title-font-size: 45px;
}

.eventTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  color: #000000;
}

.eventPrepare {
  margin-top: 20px;
  background: #F7F7F7;
  border-radius: 30px;
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.eventShowPrepare {
  text-decoration: underline;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #716CDF;
  margin: 20px 0 30px;
  cursor: pointer;
}

.eventLink {
  font-family: 'Soyuzmult';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  background: #31D693;
  border-radius: 15px;
  padding: 12px 27px 8px;
}

.events {
  display: flex;
  margin-left: -40px;
  width: auto;
}

.column {
  padding-left: 40px;
  background-clip: padding-box;
}

@media screen and (max-width: 1299px) {
  .day {
    margin-bottom: 30px;
  }

  .dayTitle {
    margin-bottom: 10px;
  }

  .wrapper {
    width: 100%;
  }
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .back {
    width: 1147px;
    height: 1037px;
  }

  .block {

  }

  .header {
    max-width: 900px;
  }

  .stars {
    height: 540px;
    position: relative;
    width: 480px;
    z-index: 2;
  }

  .star1 {
    position: absolute;
    left: 0;
    top: 200px;
  }

  .star2 {
    position: absolute;
    left: 150px;
    top: 350px;
  }

  .wrapper {
    margin-bottom: 100px;
  }
}


@media screen and (min-width: 320px) and (max-width: 719px) {
  .block {
    margin-top: 60px;
  }

  .back {
    width: 130vw;
    height: auto;

    top: max(7vw, 110px);
    right: min(-15vw, -120px);
  }

  .stars {
    height: 500px;
    position: relative;
    width: 480px;
    z-index: 2;
  }

  .star1 {
    position: absolute;
    left: 0;
    top: 180px;
  }

  .star2 {
    position: absolute;
    left: 120px;
    top: 300px;
  }

  .wrapper {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 500px) {
  .header {
    font-size: 31px;
  }
}