.block {
  max-width: var(--site-width);
  margin: 122px auto;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
  margin-bottom: 40px;

  z-index: 2;
}

.subtitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #716DDF;

  z-index: 2;
}

.wrapper {
  width: 800px;
  padding-top: 110px;
  padding-right: 110px;

  position: relative;

  z-index: 2;
}

.paper {
  background: #FFFFFF;
  border-radius: 30px 0 30px 30px;

  padding: 34px 26px;
  width: 634px;

  position: relative;
}

.hint {
  position: absolute;
  top: 0;
  right: -40px;

  width: 63px;
  height: 44px;

  background: url("./hint.svg");

  z-index: 3;
}

.star {
  position: absolute;
  right: 0;
  top: -20px;

  z-index: 2;
}

.videoWrapper {
  margin-top: 44px;
  position: relative;
  padding-bottom: 56.25%;
  z-index: 2;
}

.frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border: none;
  background-color:#fff;
}

.back {
  width: 822px;
  height: 717px;

  position: absolute;
  top: 0;
  right: -400px;

  z-index: 1;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .wrapper {
    width: 670px;
  }

  .paper {
    width: 516px;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .wrapper {
    margin-top: 20px;
    width: 100%;
  }

  .paper {
    width: calc(100% - 10px);
  }

  .star {
    position: absolute;
    right: -10px;
  }
}