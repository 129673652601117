@import-normalize;
@import 'fonts/stylesheet.css';

html {
    overflow-x: hidden;
    min-height: 100vh;
}

body {
    font-family: "Creata", "Raleway", "Helvetica", "Roboto", "Segoe UI", Arial, sans-serif;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 300;

    --site-width: 1200px;
    --site-padding-side: 40px;

    --color-1: #633CAC;
    --color-1-light: #c4a6fc;

    --color-2: #dc8036;
    --color-2-light: #fcdb59;

    --color-3: #00817a;
    --color-3-light: #2ad996;

    --color-4: #0c47aa;

    --color-0: #000000;

    margin: 0;

    background: #F4F4F4;

    scroll-behavior: smooth;

    --title-font-size: 80px;
    overflow-x: hidden;

    min-height: 100vh;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#root > *:nth-child(2) {
    flex: 1;
}

body a {
    color: unset;
    text-decoration: none;
}

body a:hover  {
    color: unset;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
    body {
        --title-font-size: 60px;
        --site-width: calc(100vw - 96px);
    }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
    body {
        --title-font-size: 46px;
        --site-width: calc(100vw - 32px);
    }
}