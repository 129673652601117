.block {
  max-width: var(--site-width);
  margin: 120px auto 40px;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
}

.back {
  background-image: url("./pattern.svg");
  background-position-x: center;
  background-repeat: repeat-y;
  padding: 1px 0;
  background-size: 2000px auto;
}

.header {
  font-family: 'Soyuzmult';
  font-size: 60px;
  line-height: 1;
  color: #716DDF;
  margin-bottom: 40px;
  z-index: 2;
}

.date {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;

  color: #000000;
}

.content {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  a {
    text-decoration: underline;
  }
}

.photo {
  width: 100%;
  border-radius: 30px;
  margin-bottom: 40px;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .header {
    font-size: 30px;
  }

  .back {
    background-size: 150vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .back {
    background-size: 200vw;
  }

  .header {
    font-size: 26px;
  }
}