.block {
  width: 1300px;
  height: 890px;
  position: relative;
  margin: 150px auto;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.desktopMap, .tabletMap, .mobileMap {
  display: none;
}

.header {
  position: absolute;
  top: 50px;
  left: 50px;

  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
}

.title {
  font-family: 'Soyuzmult';
  font-size: 30px;
  line-height: 1;

  color: #716CDF;
  margin-bottom: 6px;
}

.download {
  position: absolute;
  top: 150px;
  left: 50px;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 27px 4px;

  background: #31D693;
  border-radius: 15px;

  font-family: 'Soyuzmult';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  color: #FFFFFF;

  z-index: 1;

  &:visited, &:active {
    color: #FFFFFF;
  }
}

.text {
  font-size: 16px;
  line-height: 1.1;

  color: #000000;
}

.item1 {
  position: absolute;
  top: 378px;
  left: 52px;

  width: 168px;
}

.item2 {
  position: absolute;
  top: 351px;
  left: 373px;

  width: 209px;
}

.item3 {
  position: absolute;
  top: 185px;
  left: 678px;

  width: 198px;
}

.item4 {
  position: absolute;
  top: 262px;
  left: 999px;

  width: 167px;
}

.item5 {
  position: absolute;
  top: 520px;
  left: 1055px;

  width: 184px;
}

.item6 {
  position: absolute;
  top: 672px;
  left: 835px;

  width: 198px;
}

.item7 {
  position: absolute;
  top: 627px;
  left: 475px;

  width: 242px;
}

.item8 {
  position: absolute;
  top: 692px;
  left: 83px;

  width: 258px;
}

@media screen and (min-width: 1300px) {
  .desktopMap {
    display: block;
  }
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .block {
    width: 100vw;
    height: 196.4vw;
    overflow: hidden;

    background-image: url("./tablet.svg");
    background-size: cover;
  }

  .title {
    font-size: 3.33vw;
    margin-bottom: 0.66vw;
  }

  .text {
    font-size: 1.77vw;
  }

  .item1 {
    top: 30.3vw;
    left: 8.33vw;

    width: 18.5vw;
  }

  .item2 {
    top: 37vw;
    left: 39.1vw;

    width: 23.22vw;
  }

  .item3 {
    top: 65.77vw;
    left: 69.77vw;

    width: 22vw;
  }

  .item4 {
    top: 79.11vw;
    left: 39.11vw;

    width: 18.5vw;
  }

  .item5 {
    top: 100.22vw;
    left: 8.3vw;

    width: 20.4vw;
  }

  .item6 {
    top: 122.77vw;
    left: 39.11vw;

    width: 22vw;
  }

  .item7 {
    top: 158.22vw;
    left: 67.55vw;

    width: 26.8vw;
  }

  .item8 {
    top: 172vw;
    left: 8.66vw;

    width: 28.66vw;
  }
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .download {
    top: 120px;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .download {
    top: 14vw;
    font-size: 3vw;
  }

  .header {
    font-size: 8vw;
    top: 4vw;
  }

  .block {
    width: 100vw;
    height: 463.4vw;
    overflow: hidden;

    background-image: url("./mobile.svg");
    background-size: cover;
  }

  .title {
    font-size: 6vw;
    margin-bottom: 1.2vw;
  }

  .text {
    font-size: 3.2vw;
  }

  .item1 {
    top: 43.2vw;
    left: 4.4vw;

    width: 33.4vw;
  }

  .item2 {
    top: 88.8vw;
    left: 48vw;

    width: 41.8vw;
  }

  .item3 {
    top: 137.6vw;
    left: 11.4vw;

    width: 33vw;
  }

  .item4 {
    top: 182.64vw;
    left: 52.8vw;

    width: 28.8vw;
  }

  .item5 {
    top: 229.2vw;
    left: 10.6vw;

    width: 39.2vw;
  }

  .item6 {
    top: 282.4vw;
    left: 51.6vw;

    width: 34.8vw;
  }

  .item7 {
    top: 342vw;
    left: 11.4vw;

    width: 32.2vw;
  }

  .item8 {
    top: 422.4vw;
    left: 27.8vw;

    width: 42.8vw;
  }
}