.block {
  max-width: var(--site-width);
  margin: 122px auto 88px;
  overflow: visible;
}

.title {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;

  color: #716CDF;

  align-self: flex-start;
  z-index: 2;
}

.paper {
  background: #FFFFFF;
  border-radius: 30px;
  margin-top: 60px;

  box-sizing: border-box;
  padding: 50px 40px;

  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.cols {
  display: flex;
  justify-content: space-between;
}

.col {
  width: 530px;
}

.logo {
  width: 535px;
  height: 148px;
  display: block;
}

.logoSmall {
  display: none;
}

.top {
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid #716CDF;
}

.bottom {
  padding-top: 40px;
}

.header {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 12px;
}

.link {
  margin-top: 30px;
  display: inline-block;

  background: #0069DA;
  border-radius: 15px;

  padding: 12px 20px;

  font-family: 'Soyuzmult';

  font-size: 20px;
  line-height: 18px;

  color: #FFFFFF;

  &:hover {
    color: #FFFFFF;
  }
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .block {
    margin: 87px auto 62px;
  }

  .logo {
    display: none;
  }

  .logoSmall {
    display: block;
  }

  .logoSmallImage {
    padding-right: 76px;
    width: 100%;
    box-sizing: border-box;
  }

  .top {
    align-items: flex-end;

    & > * {
      flex: 1;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .logo {
    display: none;
  }

  .logoSmall {
    display: block;
    margin-bottom: 36px;
  }

  .top {
    flex-direction: column;
    text-align: center;
  }

  .block {
    margin-top: 45px;
  }
}

@media screen and (max-width: 1299px) {
  .bottom {
    flex-direction: column;
    padding-top: 0;
  }

  .col {
    width: unset;
    margin-top: 38px;
    display: flex;
    flex-direction: column;
  }

  .link {
    align-self: center;
  }
}