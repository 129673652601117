.block {
  max-width: var(--site-width);
  margin: 140px auto 220px;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px;

  background: #FFFFFF;
  box-shadow: 0px 34px 90px rgba(113, 108, 223, 0.2);
  border-radius: 30px;
}

.text {
  margin-top: 27px;
  font-size: 24px;
  line-height: 35px;
}

.video {
  width: 100%;
  border-radius: 20px;
  display: block;
}
