.block {
  max-width: var(--site-width);
  margin: 122px auto 200px;
  overflow: visible;
  position: relative;
}

.title {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;

  margin-bottom: 60px;

  color: #716CDF;

  align-self: flex-start;
  z-index: 2;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  z-index: 2;
  width: 374px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.cardInner {
  display: flex;
  flex-direction: column;

  background: #FFFFFF;
  border-radius: 30px;
  padding: 20px 20px 16px;

  height: 100%;
}

.cardImageWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.cardImage {
  object-position: center;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}

.cardText {
  padding: 30px;
}

.cardTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  margin-bottom: 10px;
}

.cardDescription {
  font-size: 16px;
  line-height: 24px;
}

.back1 {
  top: -229px;
  right: 300px;

  position: absolute;
  z-index: -1;
}

.back2 {
  bottom: 100px;
  right: 100px;

  position: absolute;
  z-index: -1;
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .block {
    margin: 87px auto 62px;
  }

  .card {
    width: calc(33% - 20px);
  }

  .cardText {
    padding: 18px 12px 10px;
  }

  .cardTitle {
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .block {
    margin: 60px auto;
  }

  .cards {
    flex-direction: column;
  }

  .card {
    z-index: 2;
    width: 100%;
    margin-bottom: 20px;
  }

  .cardInner {
    flex-direction: row;
    width: 100%;
  }

  .cardImageWrapper {
    width: 90px;
    min-width: 90px;
    padding-bottom: 0;
    border-radius: 15px;
    min-height: 90px;
    height: 90px;
  }

  .cardText {
    padding: 10px 24px;
  }

  .cardTitle {
    margin-bottom: 0;
  }
}