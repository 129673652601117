.block {
  max-width: var(--site-width);
  margin: 122px auto 200px;
  overflow: visible;
  position: relative;
}

.title {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;

  color: #716CDF;

  max-width: 620px;
}

.minkultV {
  margin-top: 19px;
  width: 231px;
  height: 164px;
}

.minkultH {
  display: none;
}

.palitraV {
  margin-top: 15px;
  width: 237px;
  height: 129px;
}

.palitraH {
  display: none;
}

.paper {
  background: #FFFFFF;
  border-radius: 30px;
  height: 260px;
  width: 290px;
  padding: 22px 25px;
  box-sizing: border-box;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}

.paperWide {
  width: 885px;
}

.paperFull {
  width: 100%;
}

.logos {
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coorganizer {
  margin-top: 40px;

  img {
    width: 140px;
    height: 110px;
  }
}

.partner {
  margin-top: 50px;

  img {
    width: 150px;
    height: auto;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.back1 {
  position: absolute;
  width: 1861px;
  height: 1644px;

  top: -229px;
  right: 300px;
  z-index: -1;
}

.back2 {
  display: none;
}

.infopartners {
  margin-top: 48px;

  img {
    width: 120px;
    height: auto;
  }
}

@media screen and (min-width: 720px) and (max-width: 1299px) {
  .minkultV {
    display: none;
  }

  .minkultH {
    display: block;
    width: 582px;
    height: 160px;
    margin: 30px auto 0;
  }

  .palitraV {
    display: none;
  }

  .palitraH {
    display: block;
    margin: 50px auto 0;
    width: 625px;
    height: 80px;
  }

  .row {
    flex-direction: column;

    div:not(:first-child) {
      margin-top: 30px;
    }
  }

  .paper {
    width: 100%;
    height: unset;
    min-height: 250px;
  }

  .partner {
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 30px;

    img {
      width: 245px;
      height: 140px;
    }
  }

  .block {
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1299px) {
  .partner {
    img {
      width: 290px;
      height: auto;
    }
  }
}

@media screen and (min-width: 720px) and (max-width: 850px) {
  .infopartners {
    img {
      width: 120px;
      height: auto;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .back1 {
    display: none;
  }

  .paper {
    width: 100%;
    height: unset;
    min-height: 150px;
  }

  .row {
    flex-direction: column;
    margin-top: 16px;

    div:not(:first-child) {
      margin-top: 16px;
    }
  }

  .minkultV {
    display: none;
  }

  .minkultH {
    display: block;
    width: 100%;
    height: auto;
    margin: 30px auto 0;
  }

  .palitraV {
    display: none;
  }

  .palitraH {
    display: block;
    margin: 50px auto 0;
    width: 100%;
    height: auto;
  }

  .coorganizer, .infopartners, .partner {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .block {
    margin: 60px auto 40px;
  }

  .back1 {
    display: none;
  }

  .back2 {
    display: block;
    position: absolute;
    width: 660px;
    height: 758px;

    top: 529px;
    right: -300px;
    z-index: -1;
  }
}