.block {
  max-width: var(--site-width);
  margin: 122px auto;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
}

.table {
  background: #F3ECFF;
  border: 1px solid #716CDF;
  border-radius: 30px;
  padding: 20px 40px 10px;
  margin: 40px 0;
}

.item {
  display: block;
  padding: 20px 0 14px;

  &:not(:last-child) {
    border-bottom: 1px solid #716CDF;
  }
}

.smiTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  color: #716CDF;
}

.datetime {
  margin-top: 16px;
  font-weight: 700;
  color: #000;
}

.logo {
  margin-right: 20px;
  height: 64px;
  width: auto;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  color: #000000;
}

.flex {
  display: flex;
  margin-bottom: 15px;
}

.button {
  font-family: 'Soyuzmult';
  background: #31D693;
  border-radius: 15px;
  padding: 12px 100px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  color: #FFFFFF;
  align-self: flex-end;
  cursor: pointer;
}