.block {
    position: relative;
    font-family: 'Soyuzmult';
    font-style: normal;
    font-weight: 400;
    font-size: var(--title-font-size);
    line-height: var(--highlight-line-height, 0.925);
}

.text {
    color: #82EFBA;
}

.stroke {
    color: rgba(255,0,0,0);
    -webkit-text-stroke: 1px #716CDF;
    position: absolute;
    top: -3px;
    left: -3px;
}