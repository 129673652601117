.block {
  max-width: var(--site-width);
  margin: 20px auto 40px;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
}

.anchor {
  position: relative;
  top: -320px;
}

.header {
  font-family: 'Soyuzmult';
  font-size: var(--title-font-size);
  line-height: 1;
  color: #716DDF;
  margin-bottom: 40px;
  z-index: 2;
}

.subtitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #716DDF;
  z-index: 2;
  margin-bottom: 60px;
}

.logo {
  width: 240px;
  height: 92px;
}

.back {
  position: absolute;
  top: 205px;
  right: -150px;
  width: 689px;
  height: 568px;

  z-index: -1;
}

.back2 {
  position: absolute;
  bottom: -235px;
  right: -681px;
  width: 2004px;
  height: 1570px;

  z-index: -1;
}

.stars {
  display: flex;
  margin-top: 100px;
  align-items: flex-end;
}

.col {
  background: #F3ECFF;
  border: 1px solid #716CDF;
  border-radius: 30px;
  padding: 40px 30px 30px;
  position: relative;
}

.col1 {
  margin-bottom: 60px;
  margin-right: 20px;
  flex: 1;
}

.star1 {
  position: absolute;
  top: -100px;
  right: 40px;
}

.star2 {
  position: absolute;
  top: -130px;
  left: 40px;
}

.col2 {
  flex: 1;
}

.colTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 37px;

  color: #000000;

  margin-bottom: 20px;
}

.day {
  background: #FFFFFF;
  border-radius: 30px;
  padding: 40px;
  margin-bottom: 20px;
}


.date {
  font-family: 'Soyuzmult';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 1;

  color: #716CDF;

  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #716CDF;
}

.events {
  display: flex;
  margin-left: -20px;
  width: auto;
}

.column {
  padding-left: 20px;
  background-clip: padding-box;
}

.event {
  flex: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.time {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  color: #000000;

  margin-bottom: 6px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #633CAC;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.link {
  font-family: 'Soyuzmult';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #FFFFFF;
  background: #31D693;

  border-radius: 15px;
  height: 38px;
  padding: 11px 27px 8px;

  text-decoration: none;

  margin-top: 40px;
}

.days {
  z-index: 2;
  margin-top: 40px;
}

@media screen and (max-width: 1299px) {
  .event {
    margin-bottom: 40px;
  }

  .day {
    max-width: 584px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0;
  }

  .date {
    text-align: center;
  }

  .stars {
    margin-top: 160px;
    flex-direction: column;
  }

  .star1, .star2 {
    top: -130px;
    left: 40px;
  }

  .col1 {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .events {
    text-align: center;
  }

  .back {
    top: 355px;
    width: 483px;
    height: 400px;

    z-index: -1;
  }

  .stars {
    margin-top: 360px;
  }

  .anchor {
    top: -170px;
  }
}
